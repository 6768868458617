import React from 'react'
import styled from '@emotion/styled'

import { mq, rem } from '../../utils/styles'

const Heading = styled.h2`
  font-size: ${rem(25)};
  font-weight: 600;
  letter-spacing: 0.1rem;
  line-height: 1.3;
  margin-bottom: 10px;

  ${mq.lessThan('mobile')} {
    font-size: ${rem(16)};
  }
`

type PageTitleProps = {
  children: React.ReactNode
}

const PageTitle: React.FC<PageTitleProps> = ({ children }: PageTitleProps) => (
  <Heading>{children}</Heading>
)

export default PageTitle

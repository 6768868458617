import React from 'react'
import styled from '@emotion/styled'

import Layout from '../components/Layout'
import SEO from '../components/seo'
import PageTitle from '../components/PageTitle'
import { mq, rem } from '../utils/styles'

const Container = styled.div`
  margin-top: 60px;

  ${mq.lessThan('mobile')} {
    margin-top: 30px;
  }

  a {
    border-bottom: 1px dotted #333;
    display: inline-block;
    font-size: ${rem(16)};
    letter-spacing: 0.07rem;
    margin-top: 30px;

    ${mq.lessThan('mobile')} {
      font-size: ${rem(13)};
    }
  }
`

const ContactPage = () => (
  <Layout>
    <SEO title="Contact" />
    <Container>
      <PageTitle>Contact</PageTitle>
      <a href="mailto:yiyonaga@gmail.com">Email: yiyonaga@gmail.com</a>
    </Container>
  </Layout>
)

export default ContactPage
